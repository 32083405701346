import ApiService from "@/core/services/api.service";
const querystring = require('querystring');

const COLOR_SETTING_ENDPOINT = "/api/v1/user/form-setting";
const ID_COLOR_SETTING_ENDPOINT = "/api/v1/user/form-setting-by-id";
const ID_USER_COLOR_SETTING_ENDPOINT = "/api/v1/user/user-form-setting-by-id";
const USER_COLOR_SETTING_ENDPOINT = "/api/v1/user/form-setting-by-user";
const COLOR_SETTING_LIST_ENDPOINT = "/api/v1/user/form-setting-list";

const state = {
  colorSetting: {},
  colorSettingList: []
}

const actions = {
  getColorSettingList: function(context){
    console.log(context);

    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.get(COLOR_SETTING_LIST_ENDPOINT)
        .then(res => {
          context.commit("updateColorSettingList", res.data.settingList);
          resolve();
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  getColorSettingById: function(context, colorSettingId, type){
    var query = {};

    if(colorSettingId){
      query["colorSettingId"] = colorSettingId;
    }

    if(type){
      query["type"] = type;
    }

    return new Promise((resolve, reject) => {
      var authState = context.rootState.auth;
      console.log(authState);
      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.get(ID_COLOR_SETTING_ENDPOINT, "?"+querystring.encode(query))
        .then(res => {
          if(res.data.colorSetting != null){
            context.commit("updateColorSetting", res.data.colorSetting);
          } else {
            context.commit("updateColorSetting", {
              _id: "",
              headerBackground: "#ffffff",
              headerEnabled: false,
              logoPath: "",
              phoneNumberColor: "#000000",
              headerTitleColor: "#000000",
              phoneNumberText: "",
              headerTitleText: "",
              tabsBackground: "#077ee2",
              inactiveTabsBackground: "#077ee2",
              elementColourBlock: "#077ee2",
              buttonBackground: "#077ee2",
              mainBackground: "#ffffff",
              optionBoxBackground: "#EFF8FF",
              writingColourBlock: "#020A8C",
              moreLessInfoBackground: "#077EE2",
              gettingQuoteText: "",
              thankYouMessageText: "",
              type: "live",
              twoPageQuote: false
            });
          }
          resolve(res.data.colorSetting);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  getUserColorSettingById: function(context, payload){
    console.log(payload);
    var query = {};

    if(payload){
      query["colorSettingId"] = payload.colorSettingId;
      query["type"] = payload.type;
    }

    return new Promise((resolve, reject) => {
      var authState = context.rootState.auth;
      console.log(authState);
      ApiService.setHeader();
      ApiService.get(ID_USER_COLOR_SETTING_ENDPOINT, "?"+querystring.encode(query))
        .then(res => {
          if(res.data.colorSetting != null){
            context.commit("updateColorSetting", res.data.colorSetting);
          } else {
            context.commit("updateColorSetting", {
              _id: "",
              headerBackground: "#ffffff",
              headerEnabled: false,
              logoPath: "",
              phoneNumberColor: "#000000",
              headerTitleColor: "#000000",
              phoneNumberText: "",
              headerTitleText: "",
              tabsBackground: "#077ee2",
              inactiveTabsBackground: "#077ee2",
              elementColourBlock: "#077ee2",
              buttonBackground: "#077ee2",
              mainBackground: "#ffffff",
              optionBoxBackground: "#EFF8FF",
              writingColourBlock: "#020A8C",
              moreLessInfoBackground: "#077EE2",
              gettingQuoteText: "",
              thankYouMessageText: "",
              type: "live",
              twoPageQuote: false
            });
          }
          resolve(res.data.colorSetting);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  getColorSettingByUser: function(context, payload){
    console.log(payload);
    
    var query = {};

    if(payload){
      query["_id"] = payload.colorSettingId;
      query["type"] = payload.type;
    }

    console.log(query);

    return new Promise((resolve, reject) => {
      var authState = context.rootState.auth;
      console.log(authState);
      ApiService.setPluginHeader(authState.pluginCredentials.appKey, authState.pluginCredentials.domain);
      ApiService.get(USER_COLOR_SETTING_ENDPOINT, "?"+querystring.encode(query))
        .then(res => {
          if(res.data.colorSetting != null){
            context.commit("updateColorSetting", res.data.colorSetting);
          }
          resolve(res.data.colorSetting);
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  updateColorSetting: function({ commit }, colorSetting){
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.post(COLOR_SETTING_ENDPOINT, colorSetting)
        .then(res => {
          if(res.data.success){
            resolve(res.data._id);  
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  },
  deleteColorSetting: function({ commit }, colorSettingId){
    console.log(commit);
    return new Promise((resolve, reject) => {
      ApiService.setHeader();
      ApiService.delete(COLOR_SETTING_ENDPOINT+"/"+colorSettingId)
        .then(res => {
          if(res.data.success){
            resolve(true);  
          } else {
            resolve(false);
          }
        })
        .catch(err => {
          console.log(err);
          reject();
        });
    });
  }
}

const mutations = {
  updateColorSetting(state, colorSetting) {
    state.colorSetting = colorSetting;
  },
  updateColorSettingList(state, colorSettingList) {
    state.colorSettingList = colorSettingList;
  }
}

const getters = {
  headerTitleColor: state => {
    return {
      color: state.colorSetting.headerTitleColor || ""
    }
  },
  phoneNumberColor: state => {
    return {
      color: state.colorSetting.phoneNumberColor || ""
    }
  },
  tabsBackground: state => {
    return {
      backgroundColor: state.colorSetting.tabsBackground || ""
    }
  },
  inactiveTabsBackground: state => {
    return {
      backgroundColor: state.colorSetting.tabsBackground || ""
    }
  },
  elementColourBlockBg: state => {
    return {
      backgroundColor: state.colorSetting.elementColourBlock || "#077ee2"
    }
  },
  elementColourBlockText: state => {
    return {
      color: state.colorSetting.elementColourBlock || "#077ee2"
    }
  },
  elementColourBlockBorder: state => {
    return {
      borderColor: state.colorSetting.elementColourBlock || "#077ee2"
    }
  },
  elementColourBlockBox: state => {
    return {
      borderColor: state.colorSetting.elementColourBlock || "#077ee2",
      backgroundColor: state.colorSetting.elementColourBlock || "#077ee2"
    }
  },
  
  writingColourBlockBg: state => {
    return {
      backgroundColor: state.colorSetting.writingColourBlock || "#020A8C"
    }
  },
  writingColourBlockText: state => {
    return {
      color: state.colorSetting.writingColourBlock || "#020A8C"
    }
  },
  writingColourBlockBorder: state => {
    return {
      borderColor: state.colorSetting.writingColourBlock || "#020A8C"
    }
  },
  writingColourBlockBox: state => {
    return {
      borderColor: state.colorSetting.writingColourBlock || "#020A8C",
      backgroundColor: state.colorSetting.writingColourBlock || "#020A8C"
    }
  },
  moreLessBox: state => {
    return {
      borderColor: state.colorSetting.moreLessInfoBackground || "#077ee2",
      backgroundColor: state.colorSetting.moreLessInfoBackground || "#077ee2"
    }
  },
  moreLessBorder: state => {
    return {
      borderColor: state.colorSetting.moreLessInfoBackground || "#077ee2",
    }
  },
  tabsBox: state => {
    return {
      backgroundColor: state.colorSetting.inactiveTabsBackground || "#077ee2",
      borderTopRightRadius: '8.1px',
      borderTopLeftRadius: '8.1px',
    }
  },
  tabsText: state => {
    return {
      color: state.colorSetting.tabsBackground || "#077ee2"
    }
  },
  quoteLoadingText: state => {
    return state.colorSetting.gettingQuoteText || "";
  },
  thankYouMessageText: state => {
    return state.colorSetting.thankYouMessageText || "";
  },
  phoneNumberText: state => {
    return state.colorSetting.phoneNumberText || "";
  },
  headerText: state => {
    return state.colorSetting.headerTitleText || "";
  },
  mainBg: state => {
    return {
      backgroundColor: state.colorSetting.mainBackground || "#EFF8FF"
    }
  },
  optionBox: state => {
    return {
      backgroundColor: state.colorSetting.optionBoxBackground || "#EFF8FF"
    }
  },
  buttonBox: state => {
    return {
      borderColor: state.colorSetting.buttonBackground || "#077ee2",
      backgroundColor: state.colorSetting.buttonBackground || "#077ee2"
    }
  },
  primaryButtonBox: state => {
    return {
      borderColor: state.colorSetting.mainButtonBackground || "#077ee2",
      backgroundColor: state.colorSetting.mainButtonBackground || "#077ee2"
    }
  },
  infoBubble: state => {
    return {
      color: state.colorSetting.infoBubbleBackground || "#077ee2"
    }
  },
  infoBubbleBackground: state => {
    return {
      backgroundColor: state.colorSetting.infoBubbleBackground || "#077ee2"
    }
  },
  hasHeader: state => {
    return state.colorSetting.headerEnabled || false;
  },
  headerBackground: state => {
    return {
      backgroundColor: state.colorSetting.headerBackground || "#fff"
    }
  },
  logoPath: state => {
    return process.env.VUE_APP_IMAGE_BASE+state.colorSetting.logoPath || "";
  },
  hasCustomerAgreement: state => {
    return state.colorSetting.privacyAndTos || false;
  },
  customerAgreeMessage: state => {
    return state.colorSetting.agreementMessage || false;
  },
  privacyLink: state => {
    return state.colorSetting.privacyLink || false;
  },
  tosLink: state => {
    return state.colorSetting.tosLink || false;
  },
  twoPageQuote: state => {
    return state.colorSetting.twoPageQuote || false;
  },
  applyOnlineBtnText: state => {
    return state.colorSetting.applyOnlineBtnText || "Apply Online";
  },
  externalApplication: state => {
    return state.colorSetting.externalApplication || false;
  },
  externalApplicationLink: state => {
    return state.colorSetting.externalApplicationLink;
  },
  startApplicationBtnText: state => {
    return state.colorSetting.startApplicationBtnText || "Start Application";
  },
  startApplicationSecondaryBtnText: state => {
    return state.colorSetting.startApplicationSecondaryBtnText || "Can be completed in 5 minutes";
  },
  applyBtnText: state => {
    return state.colorSetting.applyBtnText || "Apply";
  },
  hasSecondaryText: state => {
    return state.colorSetting.hasSecondaryText;
  }
}

export default {
  state,
  actions,
  mutations,
  getters
};