import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
  mode: "history",
  routes: [
    {
      path: "/pmi-quote",
      redirect: "/quote-form",
      component: () => import("@/view/pages/pmi/PMIQuote.vue"),
      children: [
        {
          path: "/quote-form",
          name: "PMIQuoteForm",
          component: () => import("@/view/pages/pmi/quote-form/QuoteForm.vue")
        },
        {
          path: "/quote-result",
          name: "PMIQuoteResult",
          component: () => import("@/view/pages/pmi/quote-results/QuoteResults.vue")
        },
        {
          path: '/quote-application',
          name: "PMIQuoteApplication",
          component: () => import('@/view/pages/pmi/quote-application/QuoteApplication.vue')
        },
        {
          path: '/quote-payment',
          name: "PMIQuotePayment",
          component: () => import('@/view/pages/pmi/quote-payment/QuotePayment.vue')
        },
      ]
    },
    {
      path: "/",
      redirect: "/leads",
      name: "Home",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "Dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/form-designer",
          name: "FormDesigner",
          component: () => import("@/view/pages/form-designer/FormDesigner.vue")
        },
        {
          path: "/leads",
          name: "Leads",
          component: () => import("@/view/pages/leads/Leads.vue")
        },
        {
          path: "/settings",
          name: "Settings",
          component: () => import("@/view/pages/settings/UserSetting.vue")
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "oauth",
          path: "/login/oauth",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});
